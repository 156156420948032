import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/student/programs/failed-students', config);
}
const exportExcel = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    config.responseType = 'arraybuffer'
    return Api.get('/student/programs/failed-students/excel-export', config);
}

export default {
    getAll,
    exportExcel
}
